/* eslint-disable */
const { REACT_APP_URL_API } = process.env;
import axios from "./axios";

const UseService = {
    async post(url, data) {
        var request = {
            method: "POST",
            url: REACT_APP_URL_API + url,
            data: data,
        };
        const resp = await axios(request);
        return resp;
    },
};
export default UseService;