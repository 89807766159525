/* eslint-disable */
import Main from "../../layout/main";
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaAngleRight, FaArrowDown } from "react-icons/fa";
import ReactPlayer from 'react-player';
import { setLocalStorage } from "../../providers/UserProvider";
import "./style.css";

const Inicio = () => {
    const [montoCursoVirtual, setMontoCursoVirtual] = useState({id: 1, monto: 600.00, nombre: "virtual"});
    const [montoCursoPresencial, setMontoCursoPresencial] = useState({id: 2, monto: 2500.00, nombre: "presencial"});
    const sectionRef1 = useRef(null);
    const navigate = useNavigate();
    const videoUrl = 'https://vimeo.com/802831958/d7dbe5669e';
    const videoCover = './images/aprende-con-diego.jpg';

    const handleScroll = (ref) => {
        // ref.current.scrollIntoView({
        //     behavior: 'smooth',
        // });
        const targetPosition = ref.current.getBoundingClientRect().top;
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        const duration = 600;
        let startTimestamp = null;

        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = timestamp - startTimestamp;
            const percentage = Math.min(progress / duration, 1);
            window.scrollTo(0, startPosition + distance * percentage);

            if (progress < duration) {
            requestAnimationFrame(step);
            }
        };

        requestAnimationFrame(step);
    };

    const handleRedirect = (event) => {
        (event.target.value == 1) ? setLocalStorage("dataCurso", montoCursoVirtual): 
        setLocalStorage("dataCurso", montoCursoPresencial);
        navigate('/pagos');
    }

    return (
        <Main>
            <section className="container-fluid portada-full">
                <div className="background-overlay"></div>
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-md-8 text-center">
                            <h1 className="fnt-4 fw-bold">100% COMPLETO</h1>
                            <div className="progress my-4" role="progressbar" aria-label="Barra de steap" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{height: "25px"}}>
                                <div className="progress-bar bg-success w-100"></div>
                            </div>
                            <h4 className="fs-4 text-white fw-semibold"><span className="fs-5 txt-verde fw-bold">3/3</span> TE ENSEÑAMOS UN MÉTODO PARA GANAR EN LAS</h4>
                            <h4 className="fs-1 text-white fw-semibold"><span className="txt-verde fw-bold">APUESTAS DEPORTIVAS.</span> 100% EFECTIVO</h4>
                            <div className="video-wrapper py-3">
                                <ReactPlayer url={videoUrl} controls={true} className="w-100"/>
                            </div>
                            <button type="button" className="btn btn-primary p-3" onClick={() => handleScroll(sectionRef1)}>
                                <FaArrowDown className="fs-2"></FaArrowDown>
                            </button>
                            <h4 className="text-verde fw-bold">Continua bajando</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid portada" ref={sectionRef1}>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12 col-md-5"></div>
                        <div className="col-12 col-md-7 text-center text-white">
                            <h3 className="fnt-2">CURSOS de</h3>
                            <h2 className="fnt-1">SUREBETS</h2>
                            <div className="franja my-5">
                                <span className="franja-font">Arbitraje Deportivo</span>
                            </div>
                            <div className="mt-5">
                                <span className="fnt-3">¡Genera ingresos 100% seguros!</span>
                                <span className="fnt-3">Conoce AQUÍ cómo utilizar las SUREBETS</span>
                            </div>
                            <div className="d-grid gap-2 px-5 pt-5 pb-2 text-center">
                                <button type="button" className="btn btn-lg btn-success fw-bold" value={1} onClick={handleRedirect}>
                                LO QUIERO YA</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-12 col-md-6 pt-5 px-3 pb-2">
                            <h3 className="fnt-4 text-center">¡Nosotros sabemos como operan las casas de apuestas y rechazamos sus métodos!</h3>
                            <div className="row">
                                <div className="col p-3">
                                    <p className="fnt-p">
                                        Lo único que buscan es hacer más ingresos aprovechándose del usuario con las <span className="fw-bold">populares limitaciones, cambios de cuota</span>  e incluso <span className="fw-bold">bloqueos</span>, esto nos lleva a pensar que las casas de apuestas nunca pierden, pero con nuestra metodología esto no es un problema ya que sin importar el resultado siempre recibirás una ganancia.
                                    </p>
                                </div>
                                <div className="col p-3">
                                    <p className="fnt-p">
                                        Para poder cumplir con nuestros objetivos tenemos una reestructurada y sólida área académica y un <span className="fw-bold">plan de estudios que va más allá de tener un curso virtual</span> ya que nuestros instructores brindan asesoramiento por canales exclusivos proporcionando contenido idóneo de acuerdo con el nivel de conocimientos.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-md-6 align-self-end">
                            <img src="./images/ARBITRO.png" alt="imagen arbitro" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="container-fluid bg-azul">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12 text-center pt-3 pb-5">
                            <h2 className="fnt-4 fw-bold">¿Qué incluye el curso?</h2>
                        </div>
                        <div className="col-md-4 px-4">
                            <img src="./images/01.png" className="img-fluid mx-auto d-block" alt="punto-01"/>
                            <h4 className="text-center text-white fw-bold px-4">cursos de arbitraje deportivo, <span className="txt-verde">Surebets</span></h4>
                            <ul className="mt-3">
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2"/>
                                    <span className="text-white">Contiene 6 MÓDULOS con más de 70 LECCIONES pregrabadas, 100% virtuales.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Podrás acceder desde donde quieras, cuando quieras.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Conocerás todo sobre las SUREBETS, MERCADOS Y CUOTAS más adecuadas.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Aprenderás los tips y consejos de los MEJORES SPORT TRADERS DEL MEDIO.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">PLAN de acción.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">BROCHURE por rango académico.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="./images/02.png" className="img-fluid mx-auto d-block" alt="punto-02" />
                            <h4 className="text-center text-white fw-bold px-4">rangos <span className="txt-verde">académicos</span></h4>
                            <ul className="mt-3">
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Beginner (rango básico)</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Sport Trader Junior (rango intermedio)</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Sport Trader Senior (rango avanzado)</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Grupos para cada rango académico.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Información de acuerdo a tu nivel de conocimientos.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="./images/03.png" className="img-fluid mx-auto d-block" alt="punto-03" />
                            <h4 className="text-center text-white fw-bold px-4">clases en vivo <span className="txt-verde">semanales</span></h4>
                            <ul className="mt-3">
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Clases semanales para cada rango académico.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Podrás interactuar con los INSTRUCTORES y SPORT TRADERS profesionales.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Las clases quedarán GRABADAS para que puedas verlas cuando quieras.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">INFORMACIÓN complementaria al aula virtual.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">PREGUNTAS Y RESPUESTAS en tiempo real.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Acceso de POR VIDA.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="./images/04.png" className="img-fluid mx-auto d-block" alt="punto-04" />
                            <h4 className="text-center text-white fw-bold px-4">guías y <span className="txt-verde">documentos</span></h4>
                            <ul className="mt-3">
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Guías de CREACIÓN y VERIFICACIÓN de cuentas en casas de apuestas.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">PDF’s y documentos de RESPALDO.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Tablas simuladoras de hándicaps.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Guías de nomenclaturas más usadas en los escáners.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Tablas de cuotas y más...</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="./images/05.png" className="img-fluid mx-auto d-block" alt="punto-05" />
                            <h4 className="text-center text-white fw-bold px-4">escáner de surebets <span className="txt-verde">50% dscto</span></h4>
                            <ul className="mt-3">
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Descuento del 50% por ser alumno para adquirir escaner CCA por primera vez.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Verás alertas tanto en PRE MATCH como en LIVE.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">ACTÍVALO solo cuando estés listo para operar.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Promoción válida hasta 15 DÍAS DESPUÉS de adquirido el curso.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Aplica para el plan LIVE FULL.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="./images/06.png" className="img-fluid mx-auto d-block" alt="punto-06" />
                            <h4 className="text-center text-white fw-bold px-4">comunidad  <span className="txt-verde">exclusiva</span></h4>
                            <ul className="mt-3">
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Tendrás soporte EXCLUSIVO en la comunidad Sport Trader Profesional.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Tendrás acceso INMEDIATO y DE POR VIDA.</span>
                                </li>
                                <li className="py-1">
                                    <FaArrowRight className="txt-verde me-2" />
                                    <span className="text-white">Podrás comunicarte y solucionar tus dudas con los Sport Trader Profesionales en TIEMPO REAL.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-12 d-grid gap-2 pt-5 pb-2 text-center">
                            <button type="button" className="btn btn-lg btn-success fw-bold" value={1} onClick={handleRedirect}>ME QUIERO MATRICULAR</button>
                        </div>
                    </div>
                </div>
                
            </section>
            {/*QUIERO UNIRME YA*/}
            <section className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-4 py-5 px-4">
                        <img src="./images/aprende-arbitraje-deportivo.png" className="img-fluid mx-auto d-block" alt="aprende" />
                        <ul className="mt-3">
                            <li className="py-1">
                                <FaAngleRight className="txt-verde me-2" />
                                <span className="fs-4 fw-semibold">Curso 100% virtual.</span>
                            </li>
                            <li className="py-1">
                                <FaAngleRight className="txt-verde me-2" />
                                <span className="fs-4 fw-semibold">Clases EN VIVO semanales.</span>
                            </li>
                            <li className="py-1">
                                <FaAngleRight className="txt-verde me-2" />
                                <span className="fs-4 fw-semibold">Guías y DOCUMENTOS.</span>
                            </li>
                            <li className="py-1">
                                <FaAngleRight className="txt-verde me-2" />
                                <span className="fs-4 fw-semibold">Rangos ACADÉMICOS.</span>
                            </li>
                            <li className="py-1">
                                <FaAngleRight className="txt-verde me-2" />
                                <span className="fs-4 fw-semibold">Comunidad exclusiva.</span>
                            </li>
                            <li className="py-1">
                                <FaAngleRight className="txt-verde me-2" />
                                <span className="fs-4 fw-semibold">Asistencia y soporte.</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-8 bg-verde py-5 px-4">
                        <div className="container">
                            <h4 className="text-white fnt-t text-center">Aprende a hacer <span className="fw-bold">surebets </span>
                                (apuestas seguras) utilizando el sistema de las <span className="fw-bold">casas de apuestas</span> a tu favor.</h4>
                            <div className="text-center pt-5 px-4">
                                <h5 className="text-white fw-bold fnt-t2 pt-5">$600 <span className="fnt-t3">USD</span></h5>
                                <h6 className="text-white fst-italic fs-4 px-4">Importante: El precio puede ser reajustado en cualquier momento.</h6>
                                <div className="col-md-12 pt-3 pb-2 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-lg btn-primary text-white fw-bold" value={1} onClick={handleRedirect}>QUIERO UNIRME YA</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*FAQ*/}
            <section className="container-fluid">
                <div className="container">
                    <div className="row justify-content-md-center py-5">
                        <div className="col-12 text-center">
                            <h3 className="fnt-5 txt-azul">Preguntas frecuentes</h3>
                        </div>
                        <div className="col-12 col-md-8 mt-4">
                            <div className="accordion" id="accordionFaq">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button fw-bold fnt-p" type="button" data-bs-toggle="collapse" data-bs-target="#FaqOne" aria-expanded="true" aria-controls="FaqOne">
                                        ¿Cuál es la metodología?
                                        </button>
                                    </h2>
                                    <div id="FaqOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFaq">
                                        <div className="accordion-body px-4">
                                            <p className="fnt-p2">Se basa en micro-learning o micro lecciones, ordenadas desde los fundamentos hasta la operatividad, cuenta con ejercicios prácticos y ejemplos, 
                                            también dentro de los módulos encontrarás prácticas y evaluaciones calificadas que permitirán evaluar tu nivel de conocimiento.</p>
                                            <p className="fnt-p2">Nuestro curso cuenta con 6 módulos (c/u con 74 lecciones)</p>
                                            <ul className="mt-3">
                                                <li className="py-1">
                                                    <FaAngleRight className="me-2" />
                                                    <span className="">Beginner.</span>
                                                </li>
                                                <li className="py-1">
                                                    <FaAngleRight className="me-2" />
                                                    <span className="">Sport Trader Junior.</span>
                                                </li>
                                                <li className="py-1">
                                                    <FaAngleRight className="me-2" />
                                                    <span className="">Sport Trader Ejecutivo.</span>
                                                </li>
                                                <li className="py-1">
                                                    <FaAngleRight className="me-2" />
                                                    <span className="">Sport Trader Senior.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold fnt-p" type="button" data-bs-toggle="collapse" data-bs-target="#FaqTwo" aria-expanded="false" aria-controls="FaqTwo">
                                        ¿Qué se necesita para operar?
                                        </button>
                                    </h2>
                                    <div id="FaqTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                        <div className="accordion-body">
                                            <p className="fnt-p2">Una vez finalizado el curso, es necesario un capital mínimo para
                                            empezar a operar, que es de $300 USD, los cuales se dividen en:</p>
                                            <ul className="mt-3">
                                                <li className="py-1">
                                                    <FaAngleRight className="me-2" />
                                                    <span className="">$200 USD en las casas de apuestas.</span>
                                                </li>
                                                <li className="py-1">
                                                    <FaAngleRight className="me-2" />
                                                    <span className="">$100 USD en las cuentas de banco para hacer las recargas.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold fnt-p" type="button" data-bs-toggle="collapse" data-bs-target="#FaqThree" aria-expanded="false" aria-controls="FaqThree">
                                        ¿Rentabilidad?
                                        </button>
                                    </h2>
                                    <div id="FaqThree" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                        <div className="accordion-body">
                                            <p className="fnt-p2">Las operaciones manejan un rango de 2% a 5% y este porcentaje es directamente proporcional a la cantidad total de stake en las operaciones.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold fnt-p" type="button" data-bs-toggle="collapse" data-bs-target="#Faqfour" aria-expanded="false" aria-controls="Faqfour">
                                        ¿Retorno de la inversión?
                                        </button>
                                    </h2>
                                    <div id="Faqfour" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                        <div className="accordion-body">
                                            <p className="fnt-p2">De acuerdo con la curva de aprendizaje y el capital invertido el retorno ocurre en promedio a los 4 a 6 meses, esto solo es una referencia a 
                                            veces puede tardar un poco más y también puede ocurrir antes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold fnt-p" type="button" data-bs-toggle="collapse" data-bs-target="#FaqFive" aria-expanded="false" aria-controls="FaqFive">
                                        Limitación y bloqueos
                                        </button>
                                    </h2>
                                    <div id="FaqFive" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                        <div className="accordion-body">
                                            <p className="fnt-p2">Dentro de nuestro curso y con la asistencia de nuestros damos las pautas para prolongar las limitaciones y evitar los bloqueos, hay que tener claro 
                                            que las limitaciones van a llegar, pero hay formas de prolongar ello, con nosotros aprenderás esas estrategias.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            {/*DESCUENTO*/}
            <section className="container-fluid bg-azul">
                <div className="container py-5">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-md-10 text-center">
                            <h3 className="fs-2 fw-bold text-white">QUIERO APROVECHAR UN DESCUENTO ADICIONAL</h3>
                            <div className="col-md-12 text-center d-grid gap-2">
                                <button type="button" className="btn btn-lg btn-success fw-bold"
                                value={1} onClick={handleRedirect}>OFERTA CURSO VIRTUAL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*¿Prefieres llevar el CURSO presencial?*/}
            <section className="container-fluid">
                <div className="container py-5">
                    <div className="row justify-content-md-center align-items-center">
                        <div className="col-2 col-md-1">
                            <img src="./images/DEDO-HACIA-ABAJO-izquierda.png" alt="mano" className="img-fluid mx-auto d-block" />
                        </div>
                        <div className="col-8 col-md-8 text-center">
                            <h3 className="fs-1 fw-bold txt-azul">¿Prefieres llevar el CURSO presencial?</h3>
                        </div>
                        <div className="col-2 col-md-1">
                            <img src="./images/DEDO-HACIA-ABAJO-derecha.png" alt="mano" className="img-fluid mx-auto d-block" />
                        </div>
                    </div>
                </div>
            </section>
            {/*CURSO PRESENCIAL*/}
            <section className="container-fluid bottom"> 
                <div style={{height: "20rem"}}></div>
                <div className="container py-5">
                    <div className="row text-center text-white">
                        <div className="col-12">
                            <h3 className="fnt-1 fw-bold fst-italic">CURSO PRESENCIAL</h3>
                        </div>
                        <div className="col-12">
                            <h4 className="fs-2 fw-semibold">Te devolvemos el 100% de tu <span className="fw-bold">inversión</span></h4>
                        </div>
                        <div className="col-12">
                            <h4 className="fs-2 fw-semibold">si no aprendes a operar con nosotros.</h4>
                        </div>
                        <div className="col-12">
                            <h4 className="fnt-2 fw-bold">$2,500 USD</h4>
                        </div>
                        <div className="col-12">
                            <h4 className="fs-2 fw-semibold">DURACION TOTAL: <span className="fw-bold">2 semanas</span></h4>
                        </div>
                        <div className="col-12 mt-5">
                            <h4 className="display-3 fw-semibold">Beneficios del curso presencial.</h4>
                        </div>
                    </div>
                    <div className="row justify-content-md-center pt-5 text-center text-white">
                        <div className="col-md-2">
                            <img src="./images/01.png" alt="01"/>
                            <h3 className="fs-4 fw-semibold">opera en menos de dos semanas</h3>
                        </div>
                        <div className="col-md-2">
                            <img src="./images/02.png" alt="02"/>
                            <h3 className="fs-4 fw-semibold">aprende con los expertos</h3>
                        </div>
                        <div className="col-md-2">
                            <img src="./images/03.png" alt="03"/>
                            <h3 className="fs-4 fw-semibold">80% práctica 20% teoría</h3>
                        </div>
                        <div className="col-md-2">
                            <img src="./images/04.png" alt="04"/>
                            <h3 className="fs-4 fw-semibold">utilizarás nuestros equipos</h3>
                        </div>
                        <div className="col-md-2">
                            <img src="./images/05.png" alt="05"/>
                            <h3 className="fs-4 fw-semibold">operaciones con sport traders</h3>
                        </div>
                    </div>
                    <div className="row justify-content-md-center pt-5">
                        <div className="col-md-8 d-grid gap-2 text-center">
                            <button type="button" className="btn btn-lg btn-primary text-white fw-bold"
                            value={2} onClick={handleRedirect}>¡QUIERO INFORMACIÓN!</button>
                        </div>
                    </div>
                </div>
            </section>
            {/*FOOTER*/}
            <section className="container-fluid bg-azul">
                <div className="row justify-content-md-center py-3 text-white text-center">
                    <div className="col-md-9">
                        <div className="row align-items-center">
                            <div className="col-md-5 py-2">
                                <span className="fs-6">@2023 CCA Academy. Todos los derechos reservados.</span>
                            </div>
                            <div className="col-md-3 py-2">
                                <span className="fs-6">Términos y condiciones</span>
                            </div>
                            <div className="col-md-3 py-2">
                                <span className="fs-6">Políticas de privacidad</span>
                            </div>
                            <div className="col-md-1 py-2">
                                <img src="./images/PERU-ES-02.png" alt="peru"/>
                                {/*<FaRegFlag></FaRegFlag>*/}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </Main>
    );
}

export default Inicio;