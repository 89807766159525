/* eslint-disable */
import Main from "../../layout/main";
import "./style.css";

const Gracias = () => {
    return (
        <Main>
            <section className="container-fluid navbar-gracias px-0">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-3 text-center">
                            <img
                                src="/images/LOGO-04-CCA.png"
                                className="img-fluid"
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid portada-gracias">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-8 text-center">
                            <h4 className="text-white fw-bold display-2 t-shadow">
                            Bienvenido a la</h4>
                            <h4 className="text-white fw-bold display-2 t-shadow">
                            comunidad CCA</h4>
                            <div className="d-grid gap-2">
                                <a href="https://wa.link/5r1bbj" target="_blank" 
                                className="btn btn-success py-2">
                                    <span className="display-6">WhatsApp +51 </span>
                                    <span className="fw-bold display-6"> 922 518 864</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Main>
    );
}

export default Gracias;