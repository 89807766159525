/* eslint-disable */
import { useLayoutEffect, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Inicio, Steap1, Steap2, Pagos, Gracias } from "./Pages";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Suspense>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Navigate to="/steap1" />} />
          <Route path="/steap1" element={<Steap1/>} />
          <Route path="/steap2" element={<Steap2/>} />
          <Route path="/inicio" element={<Inicio/>} />
          <Route path="/pagos" element={<Pagos/>} />
          <Route path="/gracias" element={<Gracias/>} />
          <Route path="*" element={<Steap1/>} />
        </Routes>
      </Wrapper>
    </Suspense>
  );
}

export default App;
