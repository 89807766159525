/* eslint-disable */
import Main from "../../layout/main";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import paises from "../../assets/paises.json";
import { yupResolver } from "@hookform/resolvers/yup";
import KRGlue from "@lyracom/embedded-form-glue";
import Swal from "sweetalert2";
import * as yup from "yup";
import { FaTrashAlt } from "react-icons/fa";
import { getLocalStorage } from "../../providers/UserProvider";
import Select from "react-select";
import PlanesService from "../../api/services/planesService";
import "./style.css";
import "../../assets/css/lyra.css";
const schemaRegistrar = yup.object({
    pais: yup
        .object()
        .shape({
            label: yup.string().required(),
            value: yup.string().required(),
        })
        .required("Seleccione un pais"),
});

let formToken = "DEMO-TOKEN-TO-BE-REPLACED";
const endpoint = process.env.REACT_APP_END_POINT;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

let dataInitial = {
    id: 1,
    nombre: "virtual",
    monto: 600,
};

const Pagos = () => {
    const [dataCursos, setDataCursos] = useState(() =>
        getLocalStorage("dataCurso", dataInitial)
    );
    const [isLoading, setIsLoading] = useState(false);
    const [isFinal, setIsFinal] = useState(false);
    const [message, setMessage] = useState("");
    const [loadingForm, setLoadingForm] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [telefono, setTelefono] = useState("");
    const [pais, setPais] = useState("");
    const [codigo, setCodigo] = useState("");
    const [direccion, setDireccion] = useState("");
    const [correo, setCorreo] = useState("");
    const [nroCupon, setNroCupon] = useState("");
    const [dscto, setDscto] = useState(0);
    const [selectedPay, setSelectedPay] = useState("izipay");
    const [existCupon, setExistCupon] = useState(false);
    const [loadCup, setLoadCup] = useState(false);
    const [cupon, setCupon] = useState(null);
    const [total, setTotal] = useState(parseFloat(dataCursos.monto).toFixed(2));
    const navigate = useNavigate();
    const {
        setValue,
        control,
    } = useForm({
        resolver: yupResolver(schemaRegistrar),
    });

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fff", borderColor: "#c3c5ca", boxShadow: "none", "&:hover": {
                borderColor: "none",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "#D0D2D7"
                    : isFocused
                        ? "#E9E9E9"
                        : "#F5F5F5",
                color: isSelected
                    ? "black"
                    : "black",
                fontWeight: "400",
                cursor: "pointer",
            };
        },
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles) => ({
            ...styles,
            color: "#5e6278",
            fontWeight: "500",
        }),
        input: (styles) => ({
            ...styles,
            color: "black",
            fontWeight: "500",
        }),
        valueContainer: (styles) => ({ ...styles, padding: "0.1rem 0.4rem", color: "black" }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: "#f9f9f9",
            
        }),
    };

    const handleButtonPay = (event) => {
        setSelectedPay(event.target.value);
    };

    const modalPay = async () => {
        setIsLoading(true);
        //DATOS A ENVIAR Y GENERAR TOKEN

        if (
            nombre == "" ||
            apellido == "" ||
            telefono == "" ||
            codigo == "" ||
            pais == "" ||
            direccion == "" ||
            correo == ""
        ) {
            Swal.fire({
                title: "Datos incompletos",
                icon: "info",
                confirmButtonColor: "#1B1D36",
                confirmButtonText: "Aceptar",
                width: 400,
            }).then((result) => {
                setIsLoading(false);
            });

            return;
        }

        const body = {
            paymentConf: {
                amount: total * 100,
                currency: "USD",
                customer: {
                    email: correo,
                    billingDetails: {
                        title: dataCursos.nombre,
                        firstName: nombre,
                        lastName: apellido,
                        phoneNumber: codigo +' '+telefono,
                        city: pais,
                        address: direccion,
                        identityCode: dataCursos.id,
                    },
                },
            },
        };
        //SERVICIO DE CREAR PAGO
        const respPay = await PlanesService.createPayment(body);
        //SI ES EXITOSO
        try {
            if (respPay.status) {
                //TOMANDO EL TOKEN GENERADO
                formToken = respPay.data.token;
                //ENVIANDO A LA LIBRERIA EL TOKEN Y TRAYENDO EL FORMULARIO
                const respuesta = await KRGlue.loadLibrary(endpoint, publicKey);
                //CONFIGURANDO EL IDIOMA DEL FORMULARIO
                respuesta.KR.setFormConfig({
                    formToken: formToken,
                    "kr-language": "es-US",
                });
                //AÑADIENDO EL FORMULARIO AL DIV
                const respForm = await respuesta.KR.addForm("#myPaymentForm");
                //TRAE ALGO EL RESPFORM
                if (respForm) {
                    await respuesta.KR.showForm(respForm.result.formId);
                }
                //FUNCION PARA ENVIAR LOS DATOS DE TARJETA
                const aaaa = await respuesta.KR.onSubmit(async (paymentData) => {
                    setLoadingForm(true);
                    //SERVICIO PARA VALIDAR PAGO
                    const respValidate = await PlanesService.validatePayment({
                        paymentData,
                        idcupon: cupon,
                    });
                    //OCULTAR Y REMOVER EL FORMULARIO Y EVENTO, ASI FUNCIONE O NO EL SERVICIO
                    respuesta.KR.hideForm(respForm.result.formId);
                    respuesta.KR.removeForms();
                    // SI LA VALIDACION ES EXITOSA
                    if (respValidate.status) {
                        setStatus(true);
                        closeModal();
                        navigate('/gracias');
                    } else {
                        //SETEAMOS VALOR DE ESTADO A FALSO Y EL MENSAJ DE ERROR
                        setStatus(false);
                        setMessage("Ocurrió un error al procesar su pago.");
                    }
                    //SETEAMOS ISFINAL A FALSE PARA REDIRECCIONAR A PERFIL (FALTARIA VER SI OCURRIÓ UN ERROR EN EL VALIDATE Y NO ENVIAR EL SETISFINAL A TRUE)
                    setIsFinal(true);
                    setShowStatus(true);
                    setLoadingForm(false);
                    return false;
                });
                //FUNCION DE ERRORES
                const errores = await respuesta.KR.onError(async () => {
                    setLoadingForm(true);
                    respuesta.KR.hideForm(respForm.result.formId);
                    respuesta.KR.removeForms();
                    setStatus(false);
                    setMessage("Ocurrió un error al procesar su pago.");
                    setShowStatus(true);
                    setIsFinal(false);
                    setLoadingForm(false);
                    return false;
                });
                // TERMINA EL LOADER DEL MODAL
                setLoadingForm(false);
                $("#modal_pay_plan").modal("show");
            } else {
                Swal.fire({
                    title: "Datos incorrectos",
                    text: "El " + respPay.data.message + " es incorrecto",
                    icon: "info",
                    confirmButtonColor: "#1B1D36",
                    confirmButtonText: "Aceptar",
                    width: 400,
                }).then((result) => {
                    setIsLoading(false);
                });
                setMessage(respPay.error);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingForm(false);
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        if (isFinal) {
            setShowStatus(false);
            $("#modal_pay_plan").modal("hide");
        } else {
            setShowStatus(false);
            $("#modal_pay_plan").modal("hide");
        }
    };

    const validCupon = async () => {
        setLoadCup(true)
        if (nroCupon != "") {
            const respCupon = await PlanesService.validateCupon({
                codigo: nroCupon,
            })
            if (respCupon.status == 202) {
                Swal.fire({
                    title: "Cupón inválido!",
                    icon: "warning",
                    text: "El cupon ingresado no es válido",
                });
            } else if (respCupon.status) {
                setExistCupon(true)
                setCupon(respCupon.data[0].id_cupon)
                setDscto(parseFloat(respCupon.data[0].monto).toFixed(2))
                Swal.fire({
                    title: "Cupón válido",
                    icon: "success",
                    text: "Aplicando descuento de cupón.",
                });
                //setear valor del descuento
                let tot = respCupon.data[0].monto ? ((dataCursos.monto - respCupon.data[0].monto) < 0 ? 0 : dataCursos.monto - respCupon.data[0].monto).toFixed(2) : dataCursos.monto.toFixed(2)
                setTotal(parseFloat(tot).toFixed(2))

            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al validar el cupón.",
                });
            }
        }
        setLoadCup(false)
    }

    const deleteCupon = () => {
        setExistCupon(false)
        setDscto(0);
        setTotal(parseFloat(dataCursos.monto).toFixed(2))
    }

    const searchCountry = (val) => {
        const filter = paises.filter((e) => e.nombre == val);
        if (filter[0]?.phone_code != "") {
            setCodigo("+" + filter[0].phone_code);
        }
        setPais(val);
    };

    return (
        <Main>
            <section className="container-fluid navbar-pasarela px-0">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-3 text-center">
                            <img
                                src="/images/LOGO-04-CCA.png"
                                className="img-fluid"
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid portada-pasarela">
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100 py-4">
                        <div className="col-12 col-md-7 p-4">
                            <div className="row border border-dark-subtle rounded-4 px-3 py-4">
                                <div className="col-md-12 pb-3 text-center">
                                    <h2 className="fs-4 fw-semibold">
                                        Datos personales
                                    </h2>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label
                                        htmlFor="nombreCliente"
                                        className="form-label"
                                    >
                                        Nombres <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nombreCliente"
                                        value={nombre}
                                        onChange={(e) =>
                                            setNombre(e.target.value)
                                        }
                                        autoComplete="off"
                                        placeholder="Ingresa tu nombre"
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label
                                        htmlFor="apellidosCliente"
                                        className="form-label"
                                    >
                                        Apellidos <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="apellidosCliente"
                                        value={apellido}
                                        onChange={(e) =>
                                            setApellido(e.target.value)
                                        }
                                        autoComplete="off"
                                        placeholder="Ingresa tus apellidos"
                                    />
                                </div>
                                <div className="col-md-5 mb-3">
                                    <label
                                        htmlFor="paisCliente"
                                        className="form-label"
                                    >
                                        Pais <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                        control={control}
                                        name="pais"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={paises.map((cu) => ({
                                                    label: cu.nombre,
                                                    value: cu.nombre,
                                                }))}
                                                styles={colourStyles}
                                                defaultValue=""
                                                onChange={(e) => [field.onChange(e), searchCountry(e.value)]}
                                                maxMenuHeight={125}
                                                placeholder={"Seleccionar"}
                                                noOptionsMessage={() => "Sin resultados"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label
                                        htmlFor="codigoCliente"
                                        className="form-label"
                                    >Código</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="codigoCliente"
                                        value={codigo}
                                        readOnly="on"
                                        placeholder="cod"
                                    />
                                </div>
                                <div className="col-md-5 mb-3">
                                    <label
                                        htmlFor="telefonoCliente"
                                        className="form-label"
                                    >
                                        Telefono <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="telefonoCliente"
                                        value={telefono}
                                        onChange={(e) =>
                                            setTelefono(e.target.value)
                                        }
                                        autoComplete="off"
                                        placeholder="Ingresa tu telefono"
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label
                                        htmlFor="direccionCliente"
                                        className="form-label"
                                    >
                                        Dirección <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="direccionCliente"
                                        value={direccion}
                                        onChange={(e) =>
                                            setDireccion(e.target.value)
                                        }
                                        autoComplete="off"
                                        placeholder="Ingresa tu dirección"
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label
                                        htmlFor="correoCliente"
                                        className="form-label"
                                    >
                                        Correo Electronico <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="correoCliente"
                                        value={correo}
                                        onChange={(e) =>
                                            setCorreo(e.target.value)
                                        }
                                        autoComplete="off"
                                        placeholder="Ingresa tu correo electronico"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 p-4">
                            <div className="row border border-dark-subtle rounded-4 px-3 py-4 mb-3">
                                <div className="col-md-12 text-center">
                                    <span className="fs-5 fw-semibold">
                                        Curso {dataCursos.nombre}
                                    </span>
                                </div>
                                <div className="col-md-12 py-3">
                                    <div className="input-group">
                                        <input type="text" className="form-control form-control-sm" placeholder="Ingrese un cupon" aria-label="Ingrese un cupon" aria-describedby="button-cupon" disabled={existCupon} value={nroCupon} onChange={(e) => setNroCupon(e.target.value)}/>
                                        <button className="btn btn-outline-secondary" type="button" id="button-cupon" onClick={validCupon} disabled={existCupon}>{loadCup ? 
                                            (<div className="spinner-border text-white size-spinner" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>) : "Validar"}</button>
                                    </div>  
                                </div>
                                <div className="col-md-12 text-end">
                                    <span className="fs-6 fst-italic d-block">
                                        Subtotal: <span className="fw-semibold">{parseFloat(dataCursos.monto).toFixed(2)} USD</span>
                                    </span>
                                    <span className="fs-6 fst-italic d-block">
                                    <FaTrashAlt className="text-danger me-2 cursor-mouse" onClick={deleteCupon}></FaTrashAlt> 
                                    Descuento: <span className="fw-semibold">-{dscto} USD</span>
                                    </span>
                                    <span className="fs-6 fst-italic d-block">
                                        Total: <span className="fw-semibold">{total} USD</span>
                                    </span>
                                </div>
                            </div>
                            <div className="row border border-dark-subtle rounded-4 px-3 py-4">
                                <div className="col-md-12 text-center">
                                    <h2 className="fs-4 fw-semibold">Pagos</h2>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="izipay-pay"
                                            value="izipay"
                                            onChange={handleButtonPay}
                                            checked={selectedPay === "izipay"}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="izipay-pay"
                                        >
                                            Debito y Credito (Pagos con izipay)
                                        </label>
                                    </div>
                                    {/*<div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="paypal-pay"
                                            value="paypal"
                                            onChange={handleButtonPay}
                                            checked={selectedPay === "paypal"}
                                            disabled
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="paypal-pay"
                                        >
                                            Paypal
                                        </label>
                                    </div>*/}
                                </div>
                                <div className="col-md-12 mt-3 mb-1">
                                    {selectedPay === "izipay" ? (
                                        <div className="d-grid gap-2 col-6 mx-auto">
                                            <button
                                                className="btn btn-primary"
                                                onClick={modalPay}
                                            >
                                            {isLoading ? 
                                                (<div className="spinner-border text-white size-spinner" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>) : "Pagar $"+total}    
                                            </button>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade modal-sm"
                    id="modal_pay_plan"
                    tabIndex="-1"
                    aria-modal="true"
                    role="dialog"
                    data-bs-backdrop="static"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div
                                className="modal-header"
                                id="modal_pay_plan_header"
                            >
                                <h2 className="fw-bold">Pagar</h2>
                                <div
                                    id="modal_pay_plan_close"
                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                    data-bs-dismiss="modal"
                                    onClick={() => closeModal()}
                                >
                                    <span className="svg-icon svg-icon-1">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x="6"
                                                y="17.3137"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                            ></rect>
                                            <rect
                                                x="7.41422"
                                                y="6"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(45 7.41422 6)"
                                                fill="currentColor"
                                            ></rect>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`modal-body position-relative  py-10 mx-lg-2`}
                            >
                                <div className="form">
                                    <div
                                        id="myPaymentForm"
                                        className="myPaymentForm d-flex justify-content-center align-items-center "
                                    >
                                    </div>
                                    {loadingForm ? (
                                        <div className="spinner-border text-success" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {showStatus ? (
                                        <div className="h-300px d-flex flex-column justify-content-center align-items-center gap-5">
                                            {/*status ? <CheckPay></CheckPay> : <FcCancel size={30}></FcCancel>*/}
                                            <span className="text-center fw-bolder">
                                                {message}
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Main>
    );
};

export default Pagos;
