/* eslint-disable */
import UseService from "../UseServices";

const PlanesService = {
    async createPayment(data) {
        var resp = await UseService.post("pagos/createPayment", data);
        return resp;
    },
    async validatePayment(data) {
        var resp = await UseService.post("pagos/validatePayment", data);
        return resp;
    },
    async validateCupon(data) {
        var resp = await UseService.post("cupones/", data);
        return resp;
    },
};
export default PlanesService;
