/* eslint-disable */
import Main from "../../layout/main";
import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import ReactPlayer from 'react-player';
// import "./style.css";

const Steap2 = () => {
    const videoUrl = 'https://vimeo.com/804338217/3933720566';
    const videoCover = './images/aprende-con-diego.jpg';

    return (
        <Main>
            <section className="container-fluid portada-full">
                <div className="background-overlay"></div>
                <div className="container h-100">
                    <div className="row align-items-center justify-content-center h-100">
                        <div className="col-md-10 text-center">
                            <h1 className="fnt-4 fw-bold">70% COMPLETO</h1>
                            <div className="progress my-4" role="progressbar" aria-label="Barra de steap" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{height: "25px"}}>
                                <div className="progress-bar bg-success w-75"></div>
                            </div>
                            <h4 className="fs-4 text-white fw-semibold"><span className="fs-5 txt-verde fw-bold">2/3</span> COMO HACER UNA SUREBET</h4>
                            <h4 className="fs-1 text-white fw-semibold">PASO A PASO Y CON UN EVENTO REAL</h4>
                            <div className="video-wrapper py-3">
                                <ReactPlayer url={videoUrl} controls={true} className="w-100"/>
                            </div>
                            <a href="inicio" type="button" className="btn btn-primary fw-bold p-3">
                                SIGUIENTE PASO <FaArrowRight className="fs-2 ms-2"></FaArrowRight>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </Main>
    );
}

export default Steap2;